























































import CoaSelect from "@/components/custom/select/CoaSelect.vue";
import {
  useFindMasterType,
  useLocalFilter,
  useMapMasterTypeToOption,
} from "@/hooks";
import { Option } from "@/models/class/option.class";
import { Messages } from "@/models/enums/messages.enum";
import Vue from "vue";

export default Vue.extend({
  name: "AccountDetails",
  components: {
    CoaSelect,
  },
  data() {
    return {
      formRules: {
        receivablesAccount: {
          label: "lbl_receivables_account",
          name: "receivablesAccount",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: [
            "receivablesAccount",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        revenueAccount: {
          label: "lbl_revenue_account",
          name: "revenueAccount",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: [
            "revenueAccount",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        payablesAccount: {
          label: "lbl_payables_account",
          name: "payablesAccount",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: [
            "payablesAccount",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        prepaymentAccount: {
          label: "lbl_prepayment_account",
          name: "prepaymentAccount",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: [
            "prepaymentAccount",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        termOfPayment: {
          label: "lbl_term_of_payment",
          name: "termOfPayment",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: ["termOfPayment"],
        },
      },
      optTermOfPayment: [] as Array<Option>,
      loading: {
        top: false,
      },
    };
  },
  created(): void {
    this.fetchTermOfPayments();
  },
  methods: {
    useLocalFilter,
    fetchTermOfPayments(): void {
      this.loading.top = true;
      useFindMasterType("TOP")
        .then(response => {
          this.optTermOfPayment = useMapMasterTypeToOption(response);
        })
        .finally(() => {
          this.loading.top = false;
        });
    },
  },
});

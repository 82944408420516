















































































































import { debounceProcess } from "@/helpers/debounce";
import { Mutations } from "@/models/constant/enums/mutations.enum";
import {
  OptionModel,
  PropsModel,
} from "@/models/constant/interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { Mode } from "@/models/enums/global.enum";
import { Messages } from "@/models/enums/messages.enum";
import {
  CreateContactData,
  ResponseContactData,
  ResponseListMaster,
} from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataListRelatedCode } from "@/models/interface/settings.interface";
import { contactServices } from "@/services/contact.service";
import { masterServices } from "@/services/master.service";
import { settingsServices } from "@/services/settings.service";
import moment, { Moment } from "moment";
import Vue from "vue";
import AccountDetails from "./AccountDetails.vue";

interface InterFaceBankDataList {
  bankName: string;
  bankBranch: string;
  bankAccName: string;
  bankAccNumber: string;
  key: number;
}

interface InterfaceDataEmployee {
  gender: string | null;
  dateOfBirth: Moment | string | null;
  startDate: Moment | string | null;
  position: string | null;
  nik: string | null;
}

interface InterfaceDataCustomer {
  customerType: string | null;
  creditLimit: number | null;
  discount: number | null;
  taxInvoiceCode: string | null;
}

export default Vue.extend({
  name: "CreateContact",
  components: {
    AccountDetails,
  },
  data() {
    this.handleSearchTaxInvoiceCode = debounceProcess(
      this.handleSearchTaxInvoiceCode,
      400
    );
    return {
      disabledTabPane: true as boolean,
      page: 0 as number,
      limit: 10 as number,
      idContact: "" as string,
      mode: "Create" as string,
      active: true as boolean,
      activeTabPane: "General Details" as string,
      dataContact: {} as ResponseContactData,
      employeeNumber: "" as string | null,
      customerNumber: "" as string | null,
      supplierNumber: "" as string | null,
      dataDepartment: [] as ResponseListMaster[],
      columnTabPane: [
        {
          label: "lbl_general_details",
          value: "General Details",
        },
        {
          label: "lbl_address_details",
          value: "Address Details",
        },
        {
          label: "lbl_bank_details",
          value: "Bank Details",
        },
        {
          label: "lbl_account_details",
          value: "Account Details",
        },
      ] as OptionModel[],
      loadingSave: false as boolean,
      dataTaxType: [] as ResponseListMaster[],
      dataSupplierType: [] as ResponseListMaster[],
      dataCustomerType: [] as ResponseListMaster[],
      dataTaxInvoiceCode: [] as DataListRelatedCode[],
      form: this.$form.createForm(this, { name: "contactData" }),
    };
  },
  methods: {
    setDataForm(values): void {
      for (const key in values) {
        this.form.getFieldDecorator(key, {
          initialValue: values[key],
        });
      }
      this.form.setFieldsValue(values);
    },
    handleSearchTaxInvoiceCode(valueSearch) {
      const params: RequestQueryParamsModel = {
        limit: 10,
        page: 0,
        search: "revisionCode~false",
      };
      if (valueSearch) params.search += `code~${valueSearch}`;
      settingsServices
        .listOfTaxInvoiceCode(params)
        .then(response => (this.dataTaxInvoiceCode = response.data));
    },
    handleSearchDepartment(valueSearch) {
      let params = {
        page: 0,
        limit: 10,
        name: `DEPARTMENT`,
      } as RequestQueryParamsModel;

      if (valueSearch) params.search = `value~*${valueSearch}*`;

      this.loadingStatus = true;
      masterServices
        .listMaster(params)
        .then(data => {
          this.dataDepartment = data;
        })
        .finally(() => (this.loadingStatus = false));
    },
    handleCancel() {
      this.$router.push("/contact/read");
    },
    checkColumnTabPane(err): string[] {
      let typesGeneralDetails: string[] = [];
      if (!this.columnTabPane.find(t => t.value === "Customer Details")) {
        typesGeneralDetails.push("Customer Details");
        delete err["customerType"];
      }
      if (!this.columnTabPane.find(t => t.value === "Supplier Details")) {
        typesGeneralDetails.push("Supplier Details");
        delete err["supplierType"];
      }
      if (!this.columnTabPane.find(t => t.value === "Employee Details")) {
        typesGeneralDetails.push("Employee Details");
        delete err["nik"];
        delete err["role"];
        delete err["dateOfBirth"];
        delete err["startingDate"];
      }
      return typesGeneralDetails;
    },
    handleMandatoryField(err): string[] {
      let tempDescription = [] as string[];
      if (err && Object.keys(err).length > 0) {
        Object.keys(err).forEach(key => tempDescription.push(`Form ${key},`));
        tempDescription.push("is Mandatory");
      }
      return tempDescription;
    },
    checkDataStringOrNull(value): null | string {
      if (value) return value;
      else return null;
    },
    checkDataNumberOrNull(value): null | number {
      if (value || value === 0) return value;
      else return null;
    },
    checkSupplierCustomerEmployee(): {
      supplier: boolean;
      customer: boolean;
      employee: boolean;
    } {
      const supplier = this.columnTabPane.find(
        t => t.value === "Supplier Details"
      )
        ? true
        : false;
      const customer = this.columnTabPane.find(
        t => t.value === "Customer Details"
      )
        ? true
        : false;
      const employee = this.columnTabPane.find(
        t => t.value === "Employee Details"
      )
        ? true
        : false;
      return {
        supplier,
        customer,
        employee,
      };
    },
    checkCustomerData(
      isCustomer,
      res
    ): {
      customerType: string | null;
      creditLimit: number | null;
      discount: number | null;
      taxInvoiceCode: string | null;
    } | null {
      if (isCustomer) {
        return {
          customerType: this.checkDataStringOrNull(res.customerType),
          creditLimit: this.checkDataNumberOrNull(res.creditLimit),
          discount: this.checkDataNumberOrNull(res.discount),
          taxInvoiceCode: this.checkDataStringOrNull(res.taxInvoiceCode),
        };
      } else if (this.dataContact.customerData)
        return this.dataContact.customerData;
      else return null;
    },
    checkEmployeeData(
      isEmployee,
      res
    ): {
      nik: string | null;
      gender: string | null;
      position: string | null;
      dateOfBirth: string | null;
      startDate: string | null;
    } | null {
      if (isEmployee) {
        return {
          nik: res.nik,
          gender: this.$store.state.contactStore.dataEmployee.gender, // radio button,
          position: res.role,
          dateOfBirth: this.$store.state.contactStore.dataEmployee.dateOfBirth, // date,
          startDate: this.$store.state.contactStore.dataEmployee.startDate, // date,
        };
      } else if (this.dataContact.employeeData)
        return this.dataContact.employeeData;
      else return null;
    },
    checkSupplierData(isSupplier, res): { supplierType: string | null } | null {
      if (isSupplier)
        return { supplierType: this.checkDataStringOrNull(res.supplierType) };
      else if (this.dataContact.supplierData)
        return this.dataContact.supplierData;
      else return null;
    },
    handleSave() {
      let tempDescription = [] as string[];
      let typesGeneralDetails = [] as string[];
      this.form.validateFields((err, res) => {
        // checker tab
        typesGeneralDetails = this.checkColumnTabPane(err);

        // Description Notif Field" Mandatory
        tempDescription = this.handleMandatoryField(err);

        // checker Address Data List
        if (
          this.$store.state.contactStore.listBillTo.concat(
            this.$store.state.contactStore.listShipTo
          ).length === 0
        ) {
          this.handleNotifError(
            "Tab Address Details",
            "ShipTo or BillTo at least have 1 data"
          );
        }

        let checkerBankDataRequired = false;
        const checkBank = this.handleBankDetails();
        // payloadBankDataList = checkBank.dataBank
        checkerBankDataRequired = checkBank.checkerBankDataRequired;

        // checker bank list min 1
        // if(payloadBankDataList.length < 1) {
        //   this.handleNotifError('Tab Bank Details', 'Bank Details at least have 1 data')
        // }

        // checker bank data required
        if (checkerBankDataRequired) {
          this.handleNotifError(
            "Tab Bank Details",
            "Form bankName, Form bankBranchName, Form bankAccountName, Form bankAccountNumber is Mandatory"
          );
        }

        // checker type jika tidak diisi
        if (typesGeneralDetails.length === 3) {
          this.handleNotifError(
            "Tab General Details",
            "Choose at least one type (Customer, Supplier or Employee) !"
          );
        }

        // ===========

        // stop if error
        if (
          tempDescription.length > 0 ||
          this.$store.state.contactStore.listBillTo.concat(
            this.$store.state.contactStore.listShipTo
          ).length < 1 ||
          // payloadBankDataList.length < 1 || // karena skrg bisa kosoong
          checkerBankDataRequired ||
          typesGeneralDetails.length === 3
        )
          return;

        // create or update begini

        // assign payload
        // role = supplier | customer | employee
        const isRole = this.checkSupplierCustomerEmployee();
        const customerData = this.checkCustomerData(isRole.customer, res);
        const employeeData = this.checkEmployeeData(isRole.employee, res);
        const supplierData = this.checkSupplierData(isRole.supplier, res);
        const payload = {
          supplier: isRole.supplier,
          customer: isRole.customer,
          employee: isRole.employee,
          firstName: res.firstName,
          lastName: res.lastName,
          email: res.email,
          phoneNumber: this.checkDataStringOrNull(res.phoneNumber),
          mobileNumber: this.checkDataStringOrNull(res.mobileNumber),
          fax: this.checkDataStringOrNull(res.faxNumber),
          taxRegisNumber: res.taxRegistrationNumber,
          taxRegisName: res.taxRegistrationName,

          receivableAccountId: res.receivablesAccount,
          revenueAccountId: res.revenueAccount,
          payableAccountId: res.payablesAccount,
          prepaymentAccountId: res.prepaymentAccount,
          top: this.checkDataNumberOrNull(res.termOfPayment),

          active: this.active,
          addressDataList: this.$store.state.contactStore.listBillTo.concat(
            this.$store.state.contactStore.listShipTo
          ),
          bankDataList: this.$store.state.contactStore.bankDataList,
          customerData: customerData,
          employeeData: employeeData,
          supplierData: supplierData,
        } as CreateContactData;

        switch (this.mode) {
          case Mode.EDIT:
            this.loadingSave = true;
            contactServices
              .updateContactData(payload, this.idContact)
              .then(() => {
                this.$notification.success({
                  description: Messages.UPDATE_SUCCESS,
                  message: "Success",
                  duration: 30,
                });
                this.$router.push("/contact/read");
              })
              .catch(() =>
                this.$notification.error({
                  description: Messages.UPDATE_FAIL,
                  message: "Error",
                  duration: 30,
                })
              )
              .finally(() => (this.loadingSave = false));
            break;
          case Mode.CREATE:
            this.loadingSave = true;
            contactServices
              .createContactData(payload)
              .then(() => {
                this.$notification.success({
                  description: Messages.CREATE_SUCCESS,
                  message: "Success",
                  duration: 30,
                });
                this.$router.push("/contact/read");
              })
              .catch(() =>
                this.$notification.error({
                  description: Messages.CREATE_FAIL,
                  message: "Error",
                  duration: 30,
                })
              )
              .finally(() => (this.loadingSave = false));
            break;
          default:
            break;
        }
      });

      if (tempDescription.length > 0) {
        this.handleNotifError("Error !", `${tempDescription.join(" ")}`);
      }
    },
    handleCheckGeneralDetails(err): boolean {
      let youCanPass = false;
      if (err && Object.keys(err).length > 0) {
        for (const key in err) {
          if (
            key === "firstName" ||
            key === "lastName" ||
            key === "email" ||
            key === "taxRegistrationNumber" ||
            key === "taxRegistrationName"
          ) {
            return false;
            // checker tab
          } else if (
            !this.columnTabPane.find(t => t.value === "Customer Details") &&
            !this.columnTabPane.find(t => t.value === "Supplier Details") &&
            !this.columnTabPane.find(t => t.value === "Employee Details")
          ) {
            this.$notification.error({
              message: "Error",
              description:
                "Choose at least 1 type (Customer, Supplier or Employee) !",
            });
            return false;
          } else {
            youCanPass = true;
          }
        }
      } else {
        youCanPass = true;
      }
      return youCanPass;
    },
    handleCheckAccountDetails(err): boolean {
      let youCanPass = false;
      if (err && Object.keys(err).length > 0) {
        for (const key in err) {
          if (
            key === "receivablesAccount" ||
            key === "revenueAccount" ||
            key === "payablesAccount" ||
            key === "prepaymentAccount"
          ) {
            return false;
          } else {
            youCanPass = true;
          }
        }
      } else {
        youCanPass = true;
      }
      return youCanPass;
    },
    handleCheckEmployeeDetails(err): boolean {
      let youCanPass = false;
      if (err && Object.keys(err).length > 0) {
        for (const key in err) {
          if (key === "nik" || key === "role") {
            return false;
          } else {
            youCanPass = true;
          }
        }
      } else {
        youCanPass = true;
      }
      return youCanPass;
    },
    handleCustomerDetails(err): boolean {
      let youCanPass = false;
      if (err && Object.keys(err).length > 0) {
        for (const key in err) {
          if (key === "customerType") {
            return false;
          } else {
            youCanPass = true;
          }
        }
      } else {
        youCanPass = true;
      }
      return youCanPass;
    },
    handleSupplierDetails(err): boolean {
      let youCanPass = false;
      if (err && Object.keys(err).length > 0) {
        for (const key in err) {
          if (key === "supplierType") {
            return false;
          } else {
            youCanPass = true;
          }
        }
      } else {
        youCanPass = true;
      }
      return youCanPass;
    },
    handleAddressDetails(): boolean {
      let youCanPass = false;
      const checkAddress = this.$store.state.contactStore.listBillTo.concat(
        this.$store.state.contactStore.listShipTo
      );
      if (checkAddress.length === 0) {
        this.$notification.error({
          message: "Tab Address Details",
          description: "ShipTo or BillTo at least have 1 data",
        });
        return false;
      } else {
        youCanPass = true;
      }
      return youCanPass;
    },
    handleBankDetails(): PropsModel {
      let checkerBankDataRequired = false;
      const dataBank = this.$store.state.contactStore.bankDataList.filter(
        data => {
          if (!data.bankName) {
            return;
            // check required data bank
          } else if (data.bankName) {
            if (!data.bankBranch) checkerBankDataRequired = true;
            if (!data.bankAccName) checkerBankDataRequired = true;
            if (!data.bankAccNumber) checkerBankDataRequired = true;
          }
          return {
            bankName: data.bankName,
            bankBranch: data.bankBranch,
            bankAccName: data.bankAccName,
            bankAccNumber: data.bankAccNumber,
          };
        }
      );
      return {
        dataBank,
        checkerBankDataRequired,
      };
    },
    handleNotifError(message, description) {
      this.$notification.error({
        message: message,
        description: description,
      });
    },
    handleNext() {
      const index = this.columnTabPane.findIndex(
        t => t.value === this.activeTabPane
      );
      const tabNow = this.columnTabPane[index].value;
      let checkerBankDataRequired = false;
      let payloadBankDataList = [];
      let checkBank;
      // this.activeTabPane = this.columnTabPane[index + 1]
      this.form.validateFields((err: Error[]) => {
        let youCanPass = false;
        // checker tab pane
        this.checkColumnTabPane(err);
        switch (tabNow) {
          case "General Details":
            youCanPass = this.handleCheckGeneralDetails(err);
            break;
          case "Account Details":
            youCanPass = this.handleCheckAccountDetails(err);
            break;
          case "Employee Details":
            youCanPass = this.handleCheckEmployeeDetails(err);
            break;
          case "Customer Details":
            youCanPass = this.handleCustomerDetails(err);
            break;
          case "Supplier Details":
            youCanPass = this.handleSupplierDetails(err);
            break;
          case "Address Details":
            youCanPass = this.handleAddressDetails();
            break;
          case "Bank Details":
            checkBank = this.handleBankDetails();
            payloadBankDataList = checkBank.dataBank;
            checkerBankDataRequired = checkBank.checkerBankDataRequired;
            // checker bank list min 1
            // if(payloadBankDataList.length < 1) {
            //   this.handleNotifError('Tab Bank Details', 'Bank Details at least have 1 data')
            // }
            // checker bank data required
            if (checkerBankDataRequired) {
              this.handleNotifError(
                "Tab Bank Details",
                "Form bankName, Form bankBranchName, Form bankAccountName, Form bankAccountNumber is Mandatory"
              );
            }
            /* eslint-disable */
            let uniqueName = [
              ...new Set(payloadBankDataList.map(a => a["bankName"])),
            ];
            let uniqueValue = [
              ...new Set(payloadBankDataList.map(a => a["bankAccNumber"])),
            ];
            const isDuplicate =
              payloadBankDataList.length != uniqueName.length &&
              payloadBankDataList.length != uniqueValue.length;
            if (
              payloadBankDataList.length > 0 &&
              !checkerBankDataRequired &&
              !isDuplicate
            ) {
              youCanPass = true;
            } else if (payloadBankDataList.length === 0) {
              youCanPass = true;
            } else if (isDuplicate) {
              this.handleNotifError(
                "Error",
                "Cannot duplicate Bank Name and Bank Bank Account Number"
              );
            }
            break;
        }
        if (!youCanPass) return;
        this.activeTabPane = this.columnTabPane[index + 1].value;
      });
    },
    handleBack() {
      const index = this.columnTabPane.findIndex(
        t => t.value === this.activeTabPane
      );
      this.activeTabPane = this.columnTabPane[index - 1].value;
    },
    handleChangeMenu(value) {
      this.activeTabPane = value;
    },
    handleTypeGeneralDetail(value) {
      this.$store.commit(`contactStore/${Mutations.SET_VALUE_TYPE}`, value);
      this.columnTabPane = this.columnTabPane.filter(nameTabpane => {
        if (
          nameTabpane.value === "Employee Details" ||
          nameTabpane.value === "Customer Details" ||
          nameTabpane.value === "Supplier Details"
        ) {
          if (!value.includes(nameTabpane.value)) {
            return;
          } else {
            return nameTabpane.value;
          }
        } else {
          return nameTabpane.value;
        }
      });
      value.map(dataType => {
        if (this.columnTabPane.find(t => t.value === dataType)) {
          return;
        } else {
          this.columnTabPane.push({
            value: dataType,
            label: `lbl_${dataType.toLocaleLowerCase().replace(" ", "_")}`,
          });
        }
      });
    },
    getDataShipToOrBillTo(shipToOrBillTo, addressDataList) {
      if (shipToOrBillTo === "Ship To")
        return addressDataList.filter(dataFilter => dataFilter.shipTo);
      else return addressDataList.filter(dataFilter => dataFilter.billTo);
    },
    getDataEmployee(dataEmployee): InterfaceDataEmployee {
      if (dataEmployee) {
        return {
          gender: this.checkDataStringOrNull(dataEmployee.gender),
          dateOfBirth: dataEmployee.dateOfBirth
            ? dataEmployee.dateOfBirth
            : null,
          startDate: dataEmployee.startDate ? dataEmployee.startDate : null,
          position: this.checkDataStringOrNull(dataEmployee.position),
          nik: this.checkDataStringOrNull(dataEmployee.nik),
        };
      } else {
        return {
          gender: null,
          dateOfBirth: null,
          startDate: null,
          position: null,
          nik: null,
        };
      }
    },
    getDataCustomer(dataCustomer): InterfaceDataCustomer {
      if (dataCustomer) {
        return {
          customerType: this.checkDataStringOrNull(dataCustomer.customerType),
          creditLimit: this.checkDataNumberOrNull(dataCustomer.creditLimit),
          discount: this.checkDataNumberOrNull(dataCustomer.discount),
          taxInvoiceCode: this.checkDataStringOrNull(
            dataCustomer.taxInvoiceCode
          ),
        };
      } else {
        return {
          customerType: null,
          creditLimit: null,
          discount: null,
          taxInvoiceCode: null,
        };
      }
    },
    getDataSupplier(dataSupplier): { supplierType: string | null } {
      if (dataSupplier)
        return {
          supplierType: this.checkDataStringOrNull(dataSupplier.supplierType),
        };
      else return { supplierType: null };
    },
    getDataBankList(
      bankDataList: InterFaceBankDataList[]
    ): InterFaceBankDataList[] {
      return bankDataList
        ? bankDataList.map((dataMap, index) => {
            dataMap.key = index;
            return dataMap;
          })
        : [];
    },
  },
  created() {
    // set mode update / create
    this.mode = this.$route.meta.mode;

    // params
    const paramsMasterTaxType = {
      name: `TAX_TYPE`,
    } as RequestQueryParamsModel;
    const paramsSupplierType = {
      name: `SUPPLIER_TYPE`,
    } as RequestQueryParamsModel;
    const paramsCustomerType = {
      name: `CUSTOMER_TYPE`,
    } as RequestQueryParamsModel;

    // tax type
    masterServices.listMaster(paramsMasterTaxType).then(data => {
      this.dataTaxType = data;
    });

    // supplier type
    masterServices.listMaster(paramsSupplierType).then(data => {
      this.dataSupplierType = data;
    });

    // customer type
    masterServices.listMaster(paramsCustomerType).then(data => {
      this.dataCustomerType = data;
    });

    this.handleSearchDepartment("");
    this.handleSearchTaxInvoiceCode("");
  },
  mounted() {
    this.idContact = this.$route.params.id;
    if (this.mode === Mode.EDIT) {
      this.disabledTabPane = false;
      contactServices.getContactData(this.idContact).then(data => {
        this.active = data.active;
        this.dataContact = data;
        const listBillTo = this.getDataShipToOrBillTo(
          "Bill To",
          data.addressDataList
        );
        const listShipTo = this.getDataShipToOrBillTo(
          "Ship To",
          data.addressDataList
        );
        const dataEmployee = this.getDataEmployee(data.employeeData);
        const dataCustomer = this.getDataCustomer(data.customerData);
        const dataSupplier = this.getDataSupplier(data.supplierData);
        const columnTabPane = [] as string[];
        if (data.supplier) {
          columnTabPane.push("Supplier Details");
          this.columnTabPane.push({
            label: "lbl_supplier_details",
            value: "Supplier Details",
          });
        }
        if (data.employee) {
          columnTabPane.push("Employee Details");
          this.columnTabPane.push({
            label: "lbl_employee_details",
            value: "Employee Details",
          });
          this.handleSearchDepartment(dataEmployee.position);
        }
        if (data.customer) {
          columnTabPane.push("Customer Details");
          this.columnTabPane.push({
            label: "lbl_customer_details",
            value: "Customer Details",
          });
        }
        this.employeeNumber = data.employeeNumber;
        this.supplierNumber = data.supplierNumber;
        this.customerNumber = data.customerNumber;
        // data.bankDataList.map((data.index) => data.key = index)
        const newBankList = this.getDataBankList(data.bankDataList);
        this.$store.commit(
          `contactStore/${Mutations.SET_LIST_BILL_TO}`,
          listBillTo
        );
        this.$store.commit(
          `contactStore/${Mutations.SET_LIST_SHIP_TO}`,
          listShipTo
        );
        this.$store.commit(
          `contactStore/${Mutations.SET_DATA_EMPLOYEE}`,
          dataEmployee
        );
        this.$store.commit(
          `contactStore/${Mutations.SET_BANK_DATA_LIST}`,
          newBankList
        );
        this.$store.commit(
          `contactStore/${Mutations.SET_VALUE_TYPE}`,
          columnTabPane
        );
        this.setDataForm({
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phoneNumber: this.checkDataStringOrNull(data.phoneNumber),
          mobileNumber: this.checkDataStringOrNull(data.mobileNumber),
          faxNumber: this.checkDataStringOrNull(data.fax),
          taxRegistrationNumber: data.taxRegisNumber,
          taxRegistrationName: data.taxRegisName,
          receivablesAccount: data.receivableAccount?.id,
          revenueAccount: data.revenueAccount?.id,
          payablesAccount: data.payableAccount?.id, // code - description yang tampil
          prepaymentAccount: data.prepaymentAccount?.id,
          termOfPayment: this.checkDataNumberOrNull(data.top),
          active: data.active,
          customerType: dataCustomer.customerType,
          creditLimit: dataCustomer.creditLimit,
          discount: dataCustomer.discount,
          taxInvoiceCode: dataCustomer.taxInvoiceCode,
          nik: dataEmployee.nik,
          role: dataEmployee.position,
          supplierType: dataSupplier.supplierType,
        });
        if (
          dataEmployee &&
          (dataEmployee.dateOfBirth || dataEmployee.startDate)
        ) {
          this.setDataForm({
            dateOfBirth: moment(dataEmployee.dateOfBirth, DEFAULT_DATE_FORMAT),
            startingDate: moment(dataEmployee.startDate, DEFAULT_DATE_FORMAT),
          });
        }
      });
    }
  },
  destroyed() {
    this.$store.commit(`contactStore/${Mutations.SET_LIST_BILL_TO}`, []);
    this.$store.commit(`contactStore/${Mutations.SET_LIST_SHIP_TO}`, []);
    this.$store.commit(`contactStore/${Mutations.SET_DATA_EMPLOYEE}`, {
      gender: "Male",
      dateOfBirth: null,
      startDate: null,
    });
    this.$store.commit(`contactStore/${Mutations.SET_BANK_DATA_LIST}`, [
      {
        bankName: null,
        bankBranch: null,
        bankAccName: null,
        bankAccNumber: null,
        key: 0,
      },
    ]);
    this.$store.commit(`contactStore/${Mutations.SET_VALUE_TYPE}`, []);
    this.$store.commit(`contactStore/${Mutations.SET_ADDRESS_DATA_LIST}`, []);
  },
  watch: {
    "$store.state.preferenceStore.dataPreference": {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (
          newValue &&
          newValue.length > 0 &&
          this.mode &&
          this.mode.toLocaleLowerCase() === Mode.CREATE
        ) {
          const tempObject = {
            account_setup_account_receiviables: "",
            account_setup_sales_revenue: "",
            account_setup_account_payables: "",
            account_setup_prepayment_ap: "",
          };
          newValue.forEach(dataForeach => {
            switch (dataForeach.key) {
              case "account_setup_account_receiviables":
                tempObject.account_setup_account_receiviables =
                  dataForeach.value;
                break;
              case "account_setup_sales_revenue":
                tempObject.account_setup_sales_revenue = dataForeach.value;
                break;
              case "account_setup_account_payables":
                tempObject.account_setup_account_payables = dataForeach.value;
                break;
              case "account_setup_prepayment_ap":
                tempObject.account_setup_prepayment_ap = dataForeach.value;
                break;
              default:
                break;
            }
          });
          this.form.getFieldDecorator("receivablesAccount", {
            initialValue: tempObject.account_setup_account_receiviables,
          });
          this.form.getFieldDecorator("revenueAccount", {
            initialValue: tempObject.account_setup_sales_revenue,
          });
          this.form.getFieldDecorator("payablesAccount", {
            initialValue: tempObject.account_setup_account_payables,
          });
          this.form.getFieldDecorator("prepaymentAccount", {
            initialValue: tempObject.account_setup_prepayment_ap,
          });
        }
      },
    },
  },
});

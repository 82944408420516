var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    { staticClass: "mt-2", attrs: { gutter: [16, 16] } },
    [
      _c(
        "a-col",
        [
          _c(
            "a-form-item",
            {
              attrs: { label: _vm.$t(_vm.formRules.receivablesAccount.label) }
            },
            [
              _c("CoaSelect", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: _vm.formRules.receivablesAccount.decorator,
                    expression: "formRules.receivablesAccount.decorator"
                  }
                ],
                attrs: { searchBy: "isParent~false" }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.$t(_vm.formRules.revenueAccount.label) } },
            [
              _c("CoaSelect", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: _vm.formRules.revenueAccount.decorator,
                    expression: "formRules.revenueAccount.decorator"
                  }
                ],
                attrs: { searchBy: "isParent~false" }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.$t(_vm.formRules.payablesAccount.label) } },
            [
              _c("CoaSelect", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: _vm.formRules.payablesAccount.decorator,
                    expression: "formRules.payablesAccount.decorator"
                  }
                ],
                attrs: { searchBy: "isParent~false" }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.$t(_vm.formRules.prepaymentAccount.label) } },
            [
              _c("CoaSelect", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: _vm.formRules.prepaymentAccount.decorator,
                    expression: "formRules.prepaymentAccount.decorator"
                  }
                ],
                attrs: { searchBy: "isParent~false" }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.$t(_vm.formRules.termOfPayment.label) } },
            [
              _c(
                "a-select",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: _vm.formRules.termOfPayment.decorator,
                      expression: "formRules.termOfPayment.decorator"
                    }
                  ],
                  attrs: {
                    name: _vm.formRules.termOfPayment.name,
                    placeholder: _vm.$t(
                      _vm.formRules.termOfPayment.placeholder
                    ),
                    showSearch: true,
                    "option-filter-prop": "children",
                    "filter-option": _vm.useLocalFilter,
                    allowClear: true
                  }
                },
                _vm._l(_vm.optTermOfPayment, function(data) {
                  return _c(
                    "a-select-option",
                    { key: data.key, attrs: { value: data.value } },
                    [
                      _c(
                        "a-tooltip",
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v(" " + _vm._s(data.label) + " ")
                          ]),
                          _vm._v(" " + _vm._s(data.label) + " ")
                        ],
                        2
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }